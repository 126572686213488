import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Irene from "../img/irene.png"

import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO />
    <section className="hero">
      <div className="content">
        <h1>Oplev den fulde <span>kraft</span><br />fra <span>aloe vera</span></h1>
        <h2>Med produkter der sikrer dig<br />velvære og sundhed</h2>
      </div>
    </section>
    <section className="profile">
      <img src={Irene} alt="Irene Friis" className="irene-picture"/>
      <div className="message">
        <p className="title">Kære Kunde,</p>
        <p>Hos LR-Friis har vi en vision: Vi ønsker at gøre det muligt for alle, at de kan  føle sig tilfredse med deres krop og med deres liv - at de kan nå deres  potientiale og opnå deres drømme.<br />Det er selvfølgelig kun en drøm - men vi arbejder hårdt, for at komme så tæt  på den som muligt.</p>
        <p>Det er netop derfor, at vi arbejder hårdt for at komme så tæt på drømmen  som muligt.<br /> Netop derfor, udvikler vi innovative produkter for helse og skønhed, og  tilbyder muligheden for personlig og økonomisk succes.</p>
        <p>Vores mål: Mere kvalitet i dit liv!</p>
        <p>Med unikke og innovative kvalitetsprodukter, der matcher trends indenfor  helse og skønhed, er vi altid på forkant med udviklingen - og har været det  mere end 35 år.</p>
        <p>For LR spiller den unikke aloe vera plante en helt central rolle - med dens  ekstraordinære og alsidige egenskaber hører multitalentet aloe vera ganske  enkelt hjemme i alle hjem.</p>
        <p>Med Venlig Hilsen</p>
        <p className="signature">Irene Friis</p>
      </div>
    </section>
    <section className="second-hero">
      <div className="content">
        <h2>Kvalitet på</h2>
        <h3>højeste niveau</h3>
        <p>Kvalitet er kernen af alt hvad vi gør! Derfor investerer vi naturligvis også i at opnå en ekstraordinært høj produktkvalitet.</p>
      </div>
    </section>
    <section className="categories">
      <ul>
        <li className="nutrition">
          <a href="https://shop.lrworld.com/home/dk/da?PHP=eH9LTVp83HvX45%2BCljs4rw%3D%3D" target="_blank" rel="noreferrer">
            <div className="content">
              <h2>Nutrition</h2>
              <p>Livet er farverigt, afvekslende, og der er altid nok at lave.</p>
            </div>
          </a>
        </li>
        <li className="care">
          <a href="https://shop.lrworld.com/home/dk/da?PHP=eH9LTVp83HvX45%2BCljs4rw%3D%3Dl" target="_blank" rel="noreferrer">
            <div className="content">
              <h2>Pleje</h2>
              <p>Funktionelle plejeløsninger til særlige behov</p>
            </div>
          </a>
        </li>
        <li className="perfume">
          <a href="https://shop.lrworld.com/home/dk/da?PHP=eH9LTVp83HvX45%2BCljs4rw%3D%3D" target="_blank" rel="noreferrer">
            <div className="content">
              <h2>Parfume</h2>
              <p>En utrolig god duftkvalitet, eksklusive indholdsstoffer og en høj andel af parfumeolie</p>
            </div>
          </a>
        </li>
        <li className="makeup">
          <a href="https://shop.lrworld.com/home/dk/da?PHP=eH9LTVp83HvX45%2BCljs4rw%3D%3D" target="_blank" rel="noreferrer">
            <div className="content">
              <h2>Makeup</h2>
              <p>To linjer - LR Colours og LR Deluxe – med enestående sammensætninger og innovative produkter.</p>
            </div>
          </a>
        </li>
      </ul>
    </section>
    <section className="contact">
      <div className="content">
        <h2>CONTACT</h2>
        <h3>Irene Friis</h3>
        <h3><a href="tel:+4522527375">Tel. 22 52 73 75</a></h3>
        <h3><a href="mailto:irene@lr-friis.dk">irene@lr-friis.dk</a></h3>
      </div>
    </section>
  </Layout>
)

export default IndexPage
